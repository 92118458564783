<template>
    <div class="roll">
        <div class="list" :ref="'list' + type" @mouseover="selectStyle()" @mouseout="outStyle()">
            <img :src="require('@/assets/ecology/' + item + '.png')" v-for="(item, index) in data" :key="index" alt="">
            <img :src="require('@/assets/ecology/' + item + '.png')" v-for="(item, index) in data" :key="(index + 1) * 100" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Array,
                default: function() {
                    return []
                }
            },
            type: {
                type: String,
                default: "1"
            }
        }, 
        data() {
            return {
                time: "",
                initLeft: 0
            }
        },
        mounted() {
            this.transform();
        },
        methods: {
            selectStyle() {
                clearInterval(this.time);
            },
            outStyle() {
                this.transform();
            },
            transform() {
                var target = this.$refs['list' + this.type]; 
                var initLeft = this.initLeft;
                let _this = this;
				//console.log(document.body.offsetWidth)
                this.time = setInterval(function(){
                    initLeft++;
                    if(initLeft >= target.offsetWidth-1600 ){
                        initLeft = 0;
                    }
                    target.style = 'transform: translateX(-'+ initLeft +'px)';
                    _this.initLeft = initLeft;
                }, 15);
            }
        }
    }
</script>

<style scoped>
.roll {overflow: hidden; position: relative; height: 120px; pointer-events: auto;background: #f7f8fa;/* background: #fff; */}
.roll .list { white-space: nowrap; position: absolute;}
.roll .list img {display: inline-block; height: 120px;}
.roll .list img:hover {transform: scale(1.1); transition: all 0.25s;}
</style>