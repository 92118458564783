<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
* {padding: 0; margin: 0;}
a {text-decoration: none;}
html,body {width: 100%; height: 100%;}
</style>
