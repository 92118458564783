<template>
  <div class="home">
    <div class="header">
      <div class="container">
        <h1>
          <a href="/"><img src="@/assets/image/logo.png" alt="" /></a>
        </h1>
        <ul class="nav">
          <li
            :class="navIndex == index ? 'this' : ''"
            v-for="(item, index) in nav"
            :key="index"
          >
            <a :href="'#' + item.url">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>

    <section>
      <div class="bg" id="home">
        <swiper
          :options="banSwiperOption"
          ref="myBanSwiper"
          @slideChange="banSlideChange"
        >
          <swiper-slide v-for="(item, index) in 17" :key="index">
            <img
              :src="require('@/assets/image/banner/ban' + (index + 1) + '.png')"
              alt=""
            />
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section>
      <div class="jian-box">
        <div class="bigTitle" id="info">
          <div class="en">COMPANY PROFILE</div>
          <div class="zh">公司简介</div>
        </div>
        <div class="info">
          <div class="container">
            <div class="tp">
              <swiper
                :options="jianSwiperOption"
                ref="jianBanSwiper"
                @slideChange="jianSlideChange"
              >
                <swiper-slide v-for="(item, index) in 4" :key="index">
                  <img
                    :src="
                      require('@/assets/image/banner/jian' +
                        (index + 1) +
                        '.png')
                    "
                    alt=""
                  />
                </swiper-slide>
              </swiper>
            </div>
            <div class="content">
              <div class="name" style="text-align: center">
                <a href="">阿吉云（重庆）科技股份有限公司简介</a>
              </div>
              <!-- <p>&emsp;&emsp;阿吉云（重庆）科技股份有限公司，是一家专注乡村产业数字化服务的科技公司，坚 持以“两山论”和乡村产业数字化为指引，运用大数据、区块链、人工智能、物联网等技
                术手段，赋能乡村产业发展，提供乡村数字应用平台建设服务和乡村平台运营服务。致力 数字乡村产业建设，把人流引进来，让乡村资源变现；把农品卖出去，让乡村产业升级，
                实现乡村一二三产业融合发展，促进乡村产业振兴。<br>&emsp;&emsp;公司运营模式为B+P+O（区块链+物联网专属产品+运营）其拳头产品有：“阿吉云
                乡村码”——为你的美丽乡村行，提供一站式乡村本地生活的吃住行游乐购平台服务；“阿吉云生态宝”——实时呈现乡村数字生态场景，让城市消费人群享受不一样的新生
                活；“阿吉云天地宝”——致力于乡村农品阿吉云·蚂蚁链区块链溯源，赋能农业高质量发 展，支撑原产地高端品牌溢价;&emsp;"阿吉云人车宝"——通过物联网设备实时监测人流、车流，
                运用红外线感应监测系统，完成对人流、车流数据的统计。将人流、车流数据上传至云服
                务器，完成数据保存及管控，并发布到云数据平台，为社会管理及游客提供精准的数据服务。</p> -->
              <div class="c-box">
                <vue-seamless-scroll
                  :data="listDataText"
                  class="warp"
                  :class-option="classOption"
                >
                  <!-- <p>
                    &emsp;&emsp;阿吉云（重庆）科技股份有限公司，是一家专注乡村产业数智化服务的科技公司，拥有31项自主知识产权。公司坚持以“两山论”和乡村产业数智化为指引，联手蚂蚁集团、携程集团、重庆土交所、华中科技大学、重庆工商大学、重庆理工大学等科技公司、高等院校，应用大数据、云计算、区块链、人工智能、物联网等现代技术，创新研发运用阿吉云乡村码、阿吉云溯源码、“耘农旅”乡村管理码等数智产品及生态数据监测集成系统（生态宝）、作物生长环境监测集成系统（天地宝）、人车流量监测集成系统（人车宝）、水质监测集成系统（龙水宝）、人车物监测集成系统、智能安防打卡、烟雾监测预警等智能设备，通过“B+P+O”运营模型（区块链+物联网专属产品+阿吉云运营平台），及“S2G2B2C
                    ”
                    商业模式(平台赋能政府管理、经营主体、消费者)，实现产加销贯通、进销存一体、农文旅融合，数智化赋能和美乡村建设及市场保供能力提升，促进乡村全面振兴。
                    <br />
                    <strong>&emsp;&emsp;一、主要团队</strong>
                    <br />
                    &emsp;&emsp;钟家成，创始人，首席执行官(CEO)，研究生学历,重庆工商大学研究生事业导师，先后进入日本STM学院、北京大学汇丰商学院、美国斯坦福大学研修。长期致力数智乡村产业发展研究与实践，曾供职地方国有企业主要负责人、地方党委政府领导。香港恒生基业集团总裁、小猪班纳服饰股份有限公司副董事长，吉之汇国际农贸物流城创始人。<br />
                    &emsp;&emsp;齐文新，高级合伙人，首席科学家（CS），华中科技大学人工智能与自动化学院博士，北京航空航天大学博士后，曾任华中科技大学人工智能与自动化学院硕士导师，主要研究方向为物联网技术。主要著述有《基于物联网的多Agent温室控制系统的设计》、《基于暗通道先验的视频能见度测量方法》等。<br />
                    &emsp;&emsp;李培跃，高级合伙人，首席技术官(CTO)，软件工程本科学历。曾任支付宝核心团队支付核心负责人，参与余额宝、双峰插云等项目；重庆第三方支付易极付CTO，设计集团中台，支撑27条产品线；从09年接触Scrum以来，一直是敏捷开发的敏捷教练。<br />
                    &emsp;&emsp;程平，科技特派员，博士，副教授，软件设计师，重庆理工大学“青年英才”，负责公司数字技术指导。先后在计算机工程等EI、ISTP、CSSCI、CSCD核心学术期刊与报刊上发表100余篇文章，获重庆市科技进步三等奖1项。<br />
                    &emsp;&emsp;公司携手高级合伙人、首席科学家，华中科技大学原齐文新教授成立数智装备部，现有研发人员12人，产品生产基地主要在武汉。数智技术团队现有开发人员21人。市场运营人员主要来自蚂蚁、京东、美团、小红书等各大平台，运营团队总部现有运营人员10人，永川运营部现有8人。<br />
                    <strong>&emsp;&emsp;二、主要产品设备</strong><br />
                    &emsp;&emsp;（一）三大数智产品<br />
                    &emsp;&emsp;1、阿吉云乡村码。数智化赋能三产融合发展。致力把人流引进来，让乡村资源变现；把农品卖出去，让乡村产业升级，为你的美丽乡村行，提供一站式乡村本地生活的吃住行游乐购平台服务。<br />
                    &emsp;&emsp;2、阿吉云溯源码。携手蚂蚁集团，运用区块链技术，赋能一二产业，提升产业能级，创造新的价值。农特产品，通过区块链溯源形成独特的品牌背书和品牌溢价保护；共享田园，通过区块链数字场景实现土地价值倍增；乡村置业，携手土交所通过区块链生态数据促成城乡要素合理流动，促进集体经济发展和农民增收；加工环节，通过区块链数字场景把各个环节实时数智化呈现，提升全链路价值。<br />
                    &emsp;&emsp;3、“耘农旅”乡村管理码。运用先进的数智模块、大数据算法模型以及实时的预警提示，为政府管理提供可靠的数据支撑。通过对墒情、气候、苗情监测及预警服务，为农事管理提供数据支持；通过对生态数据监测，依据国际国内通行标准建立算法模型，并实时呈现人体舒适度指数、空气质量指数、负氧离子指数，实现生态优势向经济优势转换，为乡村生态管理提供数智赋能；通过对人车流数据、产业交易数据、经营主体参与度、业态分布动态监测及投资与消费者数据画像，为乡村社会管理提供动态数据服务；通过对道路、消防、水质、噪声、生活排污等监测预警，为乡村安全管理提供预警服务。<br />
                    &emsp;&emsp;（二）五大智能设备<br />
                    &emsp;&emsp;1、生态数据监测集成系统（生态宝）。运用国际先进的筒电容技术、激光散射、温湿气压数字化集成等技术，实时监测呈现乡村海拔、负氧离子、温度、湿度、气压、PM2.5、PM10、二氧化碳、噪声、风力等数字生态场景，并将生态数据应用于人体舒适度指数、空气质量指数、负氧离子指数发布，让城市消费人群享受不一样的新生活。<br />
                    &emsp;&emsp;2、生态宝•打卡神器。参照“生态宝”外观形象，实时呈现生态数据，监测噪声状况，为游客提供网红打卡点并记录打卡人数，同时也是景点景区标牌，增强游客体验感。
                    &emsp;&emsp;3、作物生长环境监测集成系统（天地宝）。采用高精度磁敏感应芯片、硅蓝光伏探测仪等技术设备，实时监测呈现海拔、温度、湿度、气压、风速、风向、日照、土壤氮磷钾、土壤温湿度电导率、土壤酸碱度等数字生态场景。一方面应用于农业专家数字化精准指导生产；另一方面应用于阿吉云•蚂蚁链区块链技术溯源，实现闭环购买，赋能农业高质量发展，支撑原产地高端品牌溢价。<br />
                    &emsp;&emsp;4、人车流量监测集成系统（人车宝）。通过物联网设备，运用高清摄像监测系统，实时监测人流、车流、车位，将流数据上传至云服务器，发布到云数据平台，为政府管理及游客提供精准的人车流数据统计分析及预警服务。<br />
                    &emsp;&emsp;5、水质监测集成系统（龙水宝）。以水质数据采集为核心，运用传感技术，结合浮标体、数据传输设备，自动监测水质常规参数（电导率、pH值、浊度、温度、溶解氧）；有机污染物;营养物质;富营养化指标等。客观记录水质状况，通过云平台为用户提供多终端数据服务，发现水质异常变化，及时提供水质污染预警。<br />
                    &emsp;&emsp;（三）一项系统集成<br />
                    &emsp;&emsp;数智乡村系统集成。运用区块链、物联网、大数据、人工智能等技术，赋能乡村产业，构建数智乡村产业应用平台，促进一二三产业深度融合发展；赋能乡村公共事业管理、社会治理，构建数智乡村治理应用平台，促进和美乡村建设。<br />
                    <strong>&emsp;&emsp;三、运营模式</strong><br />
                    &emsp;&emsp;
                    运营模式为B+P+O（区块链+物联网专属产品+阿吉云运营平台）。<br />
                    &emsp;&emsp;1、阿吉云乡村码平台（B+P+O）
                    B生态区块链数据。<br />
                    &emsp;&emsp;P生态宝——实时监测呈现乡村数字生态场景，并应用于人体舒适度指数、空气质量指数、负氧离子指数发布。<br />
                    &emsp;&emsp;O阿吉云乡村码运营平台——为你的美丽乡村行，提供一站式乡村本地生活的吃住行游乐购平台服务。联动村集体经济组织合作运营，将区域内民宿、农家乐、区块链溯源产品、共享田园、乡村置业等运营服务佣金的20%，分配给村集体经济组织，以增加村集体经济组织收入。<br />
                    &emsp;&emsp;2、阿吉云•蚂蚁链区块链溯源平台（B+P+O）
                    B产业区块链数据。<br />
                    &emsp;&emsp;P天地宝——实时监测作物生长环境数据，并应用于农业生产指导，及农品区块链技术溯源。<br />
                    &emsp;&emsp;O阿吉云•蚂蚁链区块链平台——通过感知数据和视觉数据对高端农产品进行全生命周期的数据溯源及闭环销售。<br />
                    &emsp;&emsp;3、阿吉云人车流云数据平台（B+P+O）
                    B人车流区块链数据。<br />
                    &emsp;&emsp;
                    P人车宝——通过物联网设备，运用高清摄像监测系统，实时监测人流、车流、车位状况。<br />
                    &emsp;&emsp;
                    O阿吉云•人车流云数据平台——将人流、车流、车位数据上传至云服务器，完成数据保存及管控，并发布到云数据平台，为政府管理及游客提供精准的人车流数据统计分析及预警服务。<br />
                    &emsp;&emsp;4、阿吉云水质监测云数据平台（B+P+O）
                    B水质监测区块链数据。<br />
                    &emsp;&emsp;P龙水宝——运用传感技术，结合浮标体、数据传输设备，自动监测水质常规参数（电导率、pH值、浊度、温度、溶解氧）；有机污染物;营养物质;富营养化指标等。<br />
                    &emsp;&emsp;O阿吉云•水质监测云数据平台——客观记录水质数据，精准分析水质状况，通过云平台为用户提供多终端数据服务，发现水质异常变化，及时提供水质污染预警。
                  </p> -->
                  <div>
        <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                style="font-family:方正黑体简体; font-size:18pt">阿吉云（重庆）科技股份有限公司简介</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">&#xa0;</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">阿吉云（重庆）科技股份有限公司，是一家专注乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">产业数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">服务的科技公司</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，拥有31项自主知识产权</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。公司坚持以“两山论”和乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">产业数智化为</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">指引，联手蚂蚁集团、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">携程集团</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">重庆土交</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">所</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">华中科技大学、重庆工商大学、重庆理工大学等科技公司、高等院校，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">应用</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">大数据、云计算、区块链、人工智能、物联网等现代技术</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">创新研发运用</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">阿吉云乡村码</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">阿吉云溯源码</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">“耘农旅”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">管理码</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">等</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">产品</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">及生态数据监测集成系统（生态宝）、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">作物生长环境监测集成系统（天地宝）、人车流量监测集成系统（人车宝）、水质监测集成系统（龙水宝）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、人车物监测集成</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">系统</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智能安防打卡</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">烟雾监测预警</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">等</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智能</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">通过“B+P+O”运营模型（区块链+物联网专属产品+阿吉云运营平台），及“S2G2B2C ”
                商业模式(平台赋能政府</span><span style="font-family:仿宋_GB2312; font-size:15pt">管理</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、经营主体、消费者)，实现产加销贯通、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">进销存一体、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农文旅融合，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">赋能</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">和美乡村建设</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">及市场保供能力提升</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">促进乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">全面</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">振兴。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:方正黑体简体; font-size:15pt">一</span><span
                style="font-family:方正黑体简体; font-size:15pt">、主要团队</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:30pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">钟家成，创始人，首席执行官(CEO)，研究生学历,重庆工商大学研究生事业导师，先后进入日本STM学院、北京大学汇丰商学院、美国斯坦福大学研修。长期</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">致力数智乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">产业发展研究与实践，曾供职地方国有企业主要负责人、地方党委政府领导。香港恒生基业集团总裁、小猪班纳服饰股份有限公司副董事长，吉</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">之汇国际农贸</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">物流城</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">创始人。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:30pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">齐文新，高级合伙人，首席科学家（CS），华中科技大学人工智能与自动化学院博士，北京航空航天大学博士后，曾任华中科技大学人工智能与自动化学院硕士导师，主要研究方向为物联网技术。主要著述有《基于物联网的多Agent温室控制系统的设计》、《基于暗通道先验的视频能见度测量方法》等。
            </span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:30pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">李培跃，高级合伙人，首席技术官(CTO)，软件工程本科学历。曾</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">任支付宝核心</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">团队支付核心负责人，参与余额宝、双峰插云等项目；重庆第三方支付</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">易极付</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">CTO，设计集团中台，支撑27条产品线；从09年接触Scrum以来，一直是敏捷开发的敏捷教练。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:30pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">程平，科技特派员，博士，副教授，软件设计师，重庆理工大学“青年英才”，负责公司数字技术指导。先后在计算机工程等EI、ISTP、CSSCI、CSCD核心学术期刊与报刊上发表100余篇文章，获重庆市科技进步三等奖1项。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:30pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">公司携手</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">高级合伙人、首席科学家，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">华中科技大学</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">原</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">齐文新教授成立</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">装备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">部，现有研发人员12人，产品生产基地主要在武汉。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智技术</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">团队现有</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">开发</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">人员</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">21人。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">市场运营人员主要来自蚂蚁、京东、美团、小红书等各大平台，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运营团队总部现有</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运营人员</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">10人，永川运营</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">部现有</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">8人。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:方正黑体简体; font-size:15pt">二</span><span
                style="font-family:方正黑体简体; font-size:15pt">、主要产品</span><span
                style="font-family:方正黑体简体; font-size:15pt">设备</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（一）三大数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">产品</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">1、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">阿吉云乡村码。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">赋能三产融合发展</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">致力把人流引进来，让乡村资源变现；把农品卖出去，让乡村产业升级，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">为你的美丽乡村行</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，提供一站式</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村本地</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">生活的吃住行游乐购平台服务。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">2、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">阿吉云溯源码。携手蚂蚁集团，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用区</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">块链技术，赋能一二产业，提升产业能级，创造新的价值。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">农特产品</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，通过区块链溯源形成独特的品牌背书和品牌溢价保护；</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">共享田园</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，通过区块链数字场景实现土地价值倍增；</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">乡村置业</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">携手土交所</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">通过区块链生态数据促成城乡要素合理流动，促进集体经济发展和农民增收；</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">加工环节</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，通过区块链数字场景把各个环节</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">实时数智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">化呈现，提升全链路价值。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">3、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">“耘农旅”乡村管理码。运用先进</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">的数智模块</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、大数据算法模型以及实时的预警提示，为政府管理提供可靠的数据支撑。通过对墒情、气候、苗情监测及预警服务，为</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">农事管理</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供数据支持；通过对生态数据监测，依据国际国内通行标准建立算法模型，并实时呈现人体舒适度指数、空气质量指数、负氧离子指数，实现生态优势向经济优势转换，为</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">乡村生态管理</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供数智赋能</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">；通过对人车流数据、产业交易数据、经营主体参与度、业态分布动态监测及投资与消费者数据画像，为</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">乡村社会管理</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供动态数据服务；通过对道路、消防、水质、噪声、生活排污等监测预警，为</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">乡村安全管理</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供预警服务</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（二）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">二类</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">智能设备</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">1、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">数智乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">类</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（1）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">生态数据监测集成系统（生态宝）。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用国际先进的筒电容技术、激光散射、温湿气压数字化集成等技术，实时监测呈现乡村海拔、负氧离子、温度、湿度、气压、PM2.5、PM10、二氧化碳、噪声、风力等数字生态场景，并</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">将生态数据应用于人体舒适度指数、空气质量指数、负氧离子指数发布，让城市消费人群享受不一样的新生活</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（2）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">生态宝</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">打卡神器。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">参照“生态宝”外观</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">形象，实时呈现生态数据，监测噪声状况，为游客提供</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">网红打卡</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">点并记录打卡人数，同时也是景点景区标牌，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">增强游客体验感。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（3）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">作物生长环境监测集成系统（天地宝）。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">采用高精度磁敏感应芯片、硅蓝光</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">伏</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">探测仪等技术设备，实时监测呈现海拔、温度、湿度、气压、风速、风向、日照、土壤氮磷钾、土壤温湿度电导率、土壤酸碱度等数字生态场景。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">一方面应用于农业专家数字化精准指导生产；另一方面应用于阿吉云•蚂蚁链区块链技术溯源，实现闭环购买，赋能农业高质量发展，支撑原产</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">地高端品牌溢价。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（4）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">人车流量监测集成系统（人车宝）。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">通过物联网设备，运用高清摄像监测系统，实时监测人流、车流</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，将</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">流数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">上传至云服务器，发布到</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">云数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">平台，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">为政府管理及游客提供精准的人车流数据统计分析及预警服务</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（5）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">水质监测集成系统（龙水宝）。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">以养殖水域数据采集为</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">核心，运用传感技术，自动监测水质常规参数（电导率、pH值、浊度、温度、溶解氧）；有机污染物;营养物质;富营养化指标等。客观记录</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">养殖水域</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">水质状况，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">精准分析水质</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">变化情况</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">发现水质异常，及时提供预警，以确保水产养殖良好的生长环境。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">2、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">数智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">保供</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">类</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（1）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">人车物监测集成设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">准确检测货物重量、实时监测人车流量的智能化集成设备。通过对车辆的轴或</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">轴组重进行</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">动态称量，运用大数据、云计算、人工智能、物联网等现代技术获得整车货物的重量。通过高清摄像及雷达监测系统，实时监测人车流情况，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">兼具道闸收费</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">功能。该集成设备具有占地面积小、智能化程度高、称量迅速准确、监测实时精准、安装维护简单等特点。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（２）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智能安防打卡设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用近场通信（NFC）技术，在不使用互联网的情况下相互通信，首先识别附近配备NFC的设备。该设备使用便捷，能精准记录提示市场安保巡逻情况，为市场安防管理提供智能化服务。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（３）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">烟雾监测预警设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用金属氧化物SNO2半导体测量原理及人</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">工智能，对可燃气体及烟雾进行定性测量，并根据测量情况实时发布</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">预警提示，为市场消防安全管理</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">服务。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（４）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">空气温湿度、氨气、硫化氢监测预警设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用电化学测量原理及人工智能，对空气温湿度、氨气、硫化氢进行在线监测，并根据监测情况实时发布预警提示，为市场环境监管</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">服务。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（５）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">氨气、硫化氢、臭气监测预警设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用电化学测量原理及人工智能，对氨气、硫化氢、臭气进行在线监测，并根据监测情况实时发布预警提示，为市场环境监管</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提供数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">服务。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">（６）</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">贴片式温度监测预警设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用热敏电阻效应原理及人工智能，对库房环境温度进行在线监测，并根据监测情况实时发布预警提示。该设备具有测量精度高，响应速度快、体积小易安装等特点。</span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（三）一项系统集成</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">系统集成</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用区</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">块链、物联网、大数据、人工智能等技术，赋能乡村产业，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">构建数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村产业应用平台，促进一二三产业深度融合发展；赋能乡村公共事业管理、社会治理，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">构建数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村治理应用平台，促进和美乡村建设。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:方正黑体简体; font-size:15pt">三</span><span
                style="font-family:方正黑体简体; font-size:15pt">、运营</span><span
                style="font-family:方正黑体简体; font-size:15pt">模型、</span><span
                style="font-family:方正黑体简体; font-size:15pt">商业模式</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">运营模型</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">:</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">B+P+O</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">（区块链+物联网专属产品+</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">阿吉云运营平台）。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">商业模式</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">：</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">S2G2B2C</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">(平台赋能政府、经营主体、消费者)</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">1、阿吉云乡村码平台</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">B+P+O</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">）</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">B生态区块链数据。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">P生态宝——实时</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">呈现乡村数字生态场景，并应用于人体舒适度指数、空气质量指数、负氧离子指数发布。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">O阿吉云乡村码运营平台——为你的美丽乡村行，提供一站式</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村本地</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">生活的吃住行游乐购平台服务。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">平台</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">联动村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">集体经济组织合作运营，将区域内民宿、农家乐</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、区块链溯源产品、共享</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">田园、乡村置业等</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运营服务佣金的20%，分</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">配给村</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">集体经济组织，以增加村集体经济组织收入。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">2、阿吉云•蚂蚁链区块链溯源平台</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（B+P+O）</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">B产业区块链数据。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">P天地宝——</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">实时监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">作物生长环境</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">并</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">应用于</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农业</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">生产</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">指导</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">及</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农品区块链</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">技术</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">溯源。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">O阿吉云</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">蚂蚁链区块链平台——通过感知数据和视觉数据对高端农产品进行全生命周期的数据溯源</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，并实现</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">闭环销售。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:19.8pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">3、阿吉云人车流云数据平台</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（B+P+O）</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">B人车流区块链数据。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">P人车宝—</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">—通过物联网设备，运用高清摄像监测系统，实时监测人流、车流状况</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">O阿吉云</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">人车流云数据平台——将人流、车流</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数据上传至云服务器，完成数据保存及管控，并发布到</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">云数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">平台，为政府管理及游客提供精准的人车流数据统计分析及预警服务。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">4、阿吉云水质监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">云数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">平台</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">（B+P+O）</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">B水质监测区块链数据。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">P龙水宝——运用传感技术，结合浮标体、数据传输设备，自动监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">养殖水域</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">水质常规参数（电导率、pH值、浊度、温度、溶解氧）；有机污染物;营养物质;富营养化指标等。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">O阿吉云</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">水质监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">云数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">平台——</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">客观记录养殖水域水质状况，精准分析水质变化情况，发现水质异常，及时提供预警，以</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">确保水产养殖良好的生长环境</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">５、阿吉云人车物监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">云数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt; font-weight:bold">平台（B+P+O）</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">B人车物监测区块链数据。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">P人车物监测集成设备——准确检测货物重量、实时监测人车流量的智能化集成设备。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">O阿吉云人车物</span><a name="OLE_LINK1"></a><a
                name="OLE_LINK2"><span style="font-family:仿宋_GB2312; font-size:15pt">监测</span><span
                    style="-aw-bookmark-end:OLE_LINK1"></span></a><span
                style="font-family:仿宋_GB2312; font-size:15pt">云数据</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">平台</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">—</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">—</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">运用大数据、云计算、人工智能、物联网</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、雷达监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">等现代技术</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">实时</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">监测</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">发布</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">整车货物的重量</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">以及</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">人车流情况</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:方正黑体简体; font-size:15pt">四</span><span
                style="font-family:方正黑体简体; font-size:15pt">、主要案例</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">近</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">年</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">来</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">先后</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">在</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">重庆市</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">城口、梁平、奉节、江北、大渡口、开州、永川、酉阳</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、大足</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">等区县，实施“数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">电商”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、“数商兴农</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">”、“数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">乡村”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、“智慧</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">景区</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">“</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">耘</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农旅</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">巴渝生态农旅</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">”、“</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">耘</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农旅</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">巴渝生态民宿”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">“阿吉云</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">郊享乐”（共享田园）、“阿吉云</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农村综改</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智地图”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">“</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">市场保供能力</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数智化</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">提升</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">等</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">建设</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">项目</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">三大数智产品</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">二类智能设备</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">在和</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">美乡村建设</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">、</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农文旅融合</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">发展</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">及市场保供能力提升</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">领域得到广泛应用。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">“</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">耘农旅</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">系</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数字</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">重庆</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">数字</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">三农平台的一个</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">重</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">要组成部分</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">，</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">全市和美乡村建设会议现场参观</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">“</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">耘</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">农旅</span><span
                style="font-family:宋体; font-size:15pt">•</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">巴渝生态民宿（</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">梁平</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">猎神村）数</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">智</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">化建设</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">”</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">项目</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">。</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; text-indent:20pt; widows:0"><span
                style="font-family:仿宋_GB2312; font-size:15pt">吉之汇</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">市场保供能力提升项目</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">列入全市</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">第一批保供能力提升先行区县</span><span
                style="font-family:仿宋_GB2312; font-size:15pt">重点项目。</span></p>
    </div>
                </vue-seamless-scroll>
              </div>

              <!-- <a href="">
								<el-button type="success">更多</el-button>
							</a> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section>
            <div class="bigTitle" id="industry">
                <div class="en">INDUSTRY INTRODUCTION</div>
                <div class="zh">行业介绍</div>
            </div>
            <div class="banner container">
                <swiper :options="swiperOption" ref="mySwiper" @slideChange="slideChange">
                    <swiper-slide v-for="(item, index) in 3" :key="index">
                        <div class="item">
                            <div class="l">
                                <div class="name">
                                    <a href="">数字乡村旅游行业</a>
                                </div>
                                <p>赋能乡村经济的商业化平台，为城市人群的周边游提供一站式的 <span>吃、住、行、游、乐、购</span> 的高品质乡村本地生活服务，打造美丽乡村新生活</p>
                                <div class="btn">
                                    <a href="">
                                        <el-button type="success">更多</el-button>
                                    </a>
                                </div>
                            </div> 
                            <div class="r">
                                <img src="@/assets/image/2.png" alt="">
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-btn">
                    <div :class="'prev ' + (bannerIndex > 0 ? 'this' : '')" @click="prev()"><i class="el-icon-back"></i></div>
                    <div :class="'next ' + (bannerIndex < 2 ? 'this' : '')" @click="next()"><i class="el-icon-right"></i></div>
                </div>
            </div>
        </section> -->

    <section>
      <div class="business" id="business">
        <div class="bigTitle">
          <div class="en">BUSINESS MATRIX</div>
          <div class="zh">业务矩阵</div>
        </div>
        <!-- <div class="list">
                    <div class="item" v-for="(item, index) in business" :key="index">
                        <img :src="require('@/assets/image/' + (index + 3) + '.png')" alt="">
                        <div class="name">{{item.name}}</div>
                        <p>{{ item.content }}</p>
                    </div>
                </div> -->
        <div class="business-box">
          <div class="item">
            <div class="tit-img">业务一</div>
            <div class="zhan"></div>
            <div class="title">阿吉云乡村码平台</div>
            <div class="bar"></div>
            <div class="info-title" style="letter-spacing: 1.6px">
              B（生态区块链数据）+P（阿吉云生态宝）+O（阿吉云乡村码运营平台）
            </div>
            <div class="h1">
              <span class="b">B</span>
              <span class="c">生态区块链数据</span>
            </div>
            <div class="h1">
              <span class="b">P</span>
              <span class="c"
                >阿吉云生态宝——实时呈现乡村数字生态场景，让城市消费人群享受不一样的新生活。</span
              >
            </div>
            <div class="h1">
              <span class="b">O</span>
              <span class="c"
                >阿吉云乡村码运营平台——为你的美丽乡村行，提供一站式乡村本地生活的吃住行游乐购平台服务。</span
              >
            </div>
          </div>
          <div class="item">
            <div class="tit-img">业务二</div>
            <div class="zhan"></div>
            <div class="title">阿吉云·蚂蚁链区块链溯源平台</div>
            <div class="bar"></div>
            <div class="info-title" style="letter-spacing: 1.6px">
              B（产业区块链数据）+P（阿吉云天地宝）+O（阿吉云•蚂蚁链区块链平台）
            </div>
            <div class="h1">
              <span class="b">B</span>
              <span class="c">产业区块链数据</span>
            </div>
            <div class="h1">
              <span class="b">P</span>
              <span class="c"
                >阿吉云天地宝——致力于乡村农品区块链溯源，赋能农业高质量发展，支撑原产地高端品牌溢价。</span
              >
            </div>
            <div class="h1">
              <span class="b">O</span>
              <span class="c"
                >阿吉云•蚂蚁链区块链平台——通过感知数据和视觉数据对高端农产品进行全生命周期的数据溯源及闭环销售。</span
              >
            </div>
          </div>
          <div class="item">
            <div class="tit-img">业务三</div>
            <div class="zhan"></div>
            <div class="title">阿吉云人流车流云数据平台</div>
            <div class="bar"></div>
            <div class="info-title" style="letter-spacing: 0px">
              B（人/车流区块链数据）+P（阿吉云•人车宝）+O（阿吉云•人/车流云数据平台）
            </div>
            <div class="h1">
              <span class="b">B</span>
              <span class="c">人/车流区块链数据。</span>
            </div>
            <div class="h1">
              <span class="b">P</span>
              <span class="c"
                >阿吉云•人车宝——通过物联网设备实时监测人流、车流，运用高清摄像监测系统，完成对人流、车流数据的统计。</span
              >
            </div>
            <div class="h1">
              <span class="b">O</span>
              <span class="c"
                >阿吉云•人/车流云数据平台——将人流、车流数据上传至云服务器，完成数据保存及管控，并发布到云数据平台，为社会管理提供数据服务。</span
              >
            </div>
          </div>
          <div class="item">
            <div class="tit-img">业务四</div>
            <div class="zhan"></div>
            <div class="title">数字乡村系统集成</div>
            <div class="bar"></div>
            <div class="h1">
              &emsp;&emsp;运用区块链、物联网、大数据、人工智能等前沿技术，一方面赋能乡村产业，构建数字乡村产业应用平台，促进一二三产业深度融合发展；另一方面赋能乡村社会治理，构建数字乡村治理应用平台，促进和美乡村建设。
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="case-box">
        <div class="bigTitle" id="case">
          <div class="en">CASE STUDY</div>
          <div class="zh">案例介绍</div>
        </div>
        <div class="case">
          <div class="lis">
            <img class="lis-img" :src="caseList[caseBannerIndex].src" alt="" />
            <div class="lis-info">
              <div class="bar">{{ caseList[caseBannerIndex].name }}</div>
              <div class="title">{{ caseList[caseBannerIndex].title }}</div>
              <div class="text">{{ caseList[caseBannerIndex].text }}</div>
            </div>
          </div>
          <div class="case-banner">
            <div
              class="swiper-button swiper-button-prev"
              slot="button-prev"
              @click="casePrev"
            >
              <!-- <i class="el-icon-arrow-left"></i> -->
            </div>
            <swiper
              :options="caseSwiperOption"
              ref="caseBanSwiper"
              @slideChange="caseSlideChange"
              style="height: 410px; width: 100%"
            >
              <swiper-slide v-for="(item, index) in caseList" :key="index">
                <div class="case-item">
                  <div class="case-img">
                    <img class="img" :src="item.src" alt="" />
                    <div class="title">{{ item.name }}</div>
                  </div>
                  <div class="inf">
                    <div class="tit">{{ item.title }}</div>
                    <div class="data">项目实施时间：</div>
                    <div class="time">{{ item.time }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="swiper-button swiper-button-next"
              slot="button-next"
              @click="caseNext"
            >
              <!-- <i class="el-icon-arrow-right"></i> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="case">
                <swiper :options="caseSwiperOption" ref="caseSwiper" @slideChange="caseSwiperChange">
                    <swiper-slide v-for="(item, index) in caseList" :key="index">
                        <div class="item">
                            <img src="@/assets/image/banner.png" alt="">
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button swiper-button-prev" slot="button-prev" @click="casePrev">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                    <div class="swiper-button swiper-button-next" slot="button-next" @click="caseNext">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </swiper>
                <div class="btn">
                    <el-button type="success">更多</el-button>
                </div>
            </div> -->
    </section>
    <section>
      <div class="product" id="product">
        <div class="bigTitle">
          <div class="en">PRODUCT DISPLAY</div>
          <div class="zh">产品展示</div>
        </div>
        <div class="list">
          <div
            v-for="item in productList"
            :key="item.id"
            style="width: 283px; position: relative"
          >
            <img :src="item.img" alt="" />
            <div class="mask">{{ item.title }}</div>
            <div class="title">
              <div class="title-l">售价:</div>
              <div class="title-r" style="font-weight: 600">
                {{ item.price }}
              </div>
            </div>
            <div class="txt">
              {{ item.txt }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="knowledge" id="knowledge">
        <div class="bigTitle">
          <div class="en">INTELLECTUAL PROPERTY</div>
          <div class="zh">知识产权</div>
        </div>
        <div class="list">
          <div class="knowledge-title">知识产权数量：25项</div>
          <swiper :options="quanSwiperOption" ref="jianBanSwiper">
            <swiper-slide v-for="(item, index) in 25" :key="index">
              <img
                class="img"
                :src="require('@/assets/image/quan/' + (index + 1) + '.jpg')"
                alt=""
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <section class="ecology">
      <div class="bigTitle" id="cooperation">
        <div class="en">Cooperative ecology</div>
        <div class="zh">合作生态</div>
      </div>
      <div class="scroll" style="pointer-events: none">
        <Roll :data="list1" type="1"></Roll>
        <Roll :data="list2" type="2"></Roll>
        <Roll :data="list3" type="3"></Roll>
        <div class="bg"></div>
      </div>
    </section>
    <div class="footer">
      <div class="container">
        <div class="info-box">
          <div class="tel-info">
            <div class="title">商务合作：</div>
            <div class="tel-box">
              <!-- <div class="tel-item">
                <div class="name">黄先生:182-7428-3128</div>
              </div> -->
              <div class="tel-item">
                <div class="name">李先生:139-9660-8887</div>
              </div>
            </div>
            <div style="height: 35px"></div>
            <div class="title">公司地址：</div>
            <div class="tel-box">
              <div class="tel-item">
                <div class="name">
                  重庆市渝北区两江新区天宫殿街道泰山大道中段21号湛江码头三楼
                  <!-- 重庆市江北区五里店街道创富路5号1幢3-1、4-1、7-1 -->
                </div>
              </div>
            </div>
          </div>
          <div class="tel-code">
            <!-- <div class="code-box">
              <img class="img" src="@/assets/image/qrcode1.png" alt="" />
              <div class="name">黄先生</div>
            </div> -->
            <div class="code-box">
              <img class="img" src="@/assets/image/qrcode2.png" alt="" />
              <div class="name">李先生</div>
            </div>
          </div>
        </div>
        <div class="b">
          <p>
            版权所有：阿吉云（重庆）科技股份有限公司 | 备案/许可证编号为：<a
              href="https://beian.miit.gov.cn"
              >渝ICP备2023000098号</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Vue from "vue";
import "swiper/dist/css/swiper.css";
import Roll from "@/components/roll";
Vue.directive("scroll", {
  inserted: function (el) {
    var ul = el;
    var li_height = ul.firstChild.offsetHeight;
    var speed = 30; // 滚动速度，数值越小滚动越快
    var delay = 1500; // 停留时间，越大停留时间越长
    var timer = null;
    var pause = false;
    // 创建两个副本，用于无缝滚动
    var clone1 = ul.cloneNode(true);
    var clone2 = ul.cloneNode(true);
    ul.appendChild(clone1);
    ul.appendChild(clone2);
    // 鼠标移入暂停滚动
    ul.onmouseover = function () {
      pause = true;
    };
    // 鼠标移出继续滚动
    ul.onmouseout = function () {
      pause = false;
    };
    // 开始滚动
    function start_scroll() {
      timer = setInterval(function () {
        if (!pause) {
          ul.scrollTop++;
          if (ul.scrollTop % li_height == 0) {
            clearInterval(timer);
            setTimeout(start_scroll, delay);
          }
        }
      }, speed);
    }
    // 延时开始滚动
    setTimeout(start_scroll, delay);
  },
});
export default {
  components: {
    swiper,
    swiperSlide,
    Roll,
  },
  data() {
    return {
      listDataText: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
      ],
      classOption: { step: 0.5 },
      banSwiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        spaceBetween: 0, //控制间隔
        slidesPerView: 1, //控制展示个数
        autoHeight: false,
        centeredSlides: false,
        grabCursor: false,
      },
      banBannerIndex: 0, // 大图轮播参数
      jianSwiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        spaceBetween: 0, //控制间隔
        slidesPerView: 1, //控制展示个数
        autoHeight: false,
        centeredSlides: false,
        grabCursor: false,
      },
      jianBannerIndex: 0, // 简介轮播参数
      business: [
        {
          name: "B+2P+O",
          content:
            "“阿吉云乡村码”——为你的美丽乡村行，提供一站式乡村本地生活的吃住行游乐购平台服务 “阿吉云生态宝”——实时呈现乡村数字生态场景，让城市消费人群享受不一样的新生活 “阿吉云天地宝”——致力于乡村农品阿吉云·蚂蚁链区块链溯源，赋能农业高质量发展，支撑原产地高端品牌溢价。",
        },
        {
          name: "B+P（生态宝）+O",
          content:
            "“阿吉云乡村码”——为你的美丽乡村行， “阿吉云生态宝”——实时呈现乡村数字生态场景，让城市消费人群享受不一样的新生活；",
        },
        {
          name: "B+P（天地宝）+O",
          content:
            "“阿吉云乡村码”——为你的美丽乡村行，提供一站式提供一站式乡村本地生活的吃住行游乐购平台服务乡村本地生活的吃住行游乐购平台服务 “阿吉云天地宝”——致力于乡村农品阿吉云·蚂蚁链区块链溯源，赋能农业高质量发展，支撑原产地高端品牌溢价。",
        },
        {
          name: "数字乡村系统集成",
          content:
            "利用5G、大数据、物联网、人工智能等前沿技术构建数字乡村应用平台，以炫酷视觉效果、全面数据集成、场景化业务展示为支撑，实现对乡村产业管理、设备管理、智慧农旅、乡村民宿等场景化展示及实施效率提升，同时结合大数据技术对设备设施的状态数据和运行数据智能监控、分析及挖掘，实现智能感知与决策支撑",
        },
      ], // 业务矩阵
      caseSwiperOption: {
        loop: true,
        direction: "vertical",
        autoplay: {
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        spaceBetween: 16, // 控制间隔
        slidesPerView: 3, //控制展示个数
        autoHeight: true,
        centeredSlides: false,
        grabCursor: false,
      },
      caseBannerIndex: 0, // 案例轮播参数
      caseList: [
        {
          name: "",
          title: "耘农旅·生态农旅(重庆)驾驶舱",
          time: "2023年09月",
          src: require("@/assets/image/case/11.jpg"),
        },
        {
          name: "数字乡村",
          title: "耘农旅·巴渝生态民宿(梁平猎神村)数智一张图",
          time: "2023年11月",
          src: require("@/assets/image/case/12.jpg"),
        },
        {
          name: "数字乡村",
          title: "耘农旅·金鳌田园大数据中心",
          time: "2023年12月",
          src: require("@/assets/image/case/13.jpg"),
        },
        {
          name: "数字乡村类",
          title: "酉阳县酉水河镇河湾村数字乡村建设",
          time: "2022年11月",
          src: require("@/assets/image/case/6.jpg"),
        },
        {
          name: "数字乡村类",
          title: "酉阳县楠木乡红庄村数字乡村建设",
          time: "2022年11月",
          src: require("@/assets/image/case/1.jpg"),
        }, //
        {
          name: "数商兴农类",
          title: "奉节县“数商兴农工程”安坪镇三沱村建设试点示范村项目",
          time: "2022年11月",
          src: require("@/assets/image/case/2.jpg"),
        }, //
        {
          name: "数商兴农类",
          title: "奉节县“数商兴农工程”鹤峰乡柳池村建设试点示范村项目",
          time: "2022年11月",
          src: require("@/assets/image/case/3.jpg"),
        },
        {
          name: "数字电商类",
          title: "城口县鲁渝协作龙田乡联丰村电子商务项目",
          time: "2022年10月",
          src: require("@/assets/image/case/10.jpg"),
        },
        {
          name: "数商兴农类",
          title: "江北区“数商兴农工程”建设试点示范村项目",
          time: "2022年09月",
          src: require("@/assets/image/case/8.jpg"),
        },
        {
          name: "数商兴农类",
          title: "梁平区“数商兴农工程”建设试点示范村项目",
          time: "2022年07月",
          src: require("@/assets/image/case/7.jpg"),
        },
        {
          name: "数商兴农类",
          title: "大渡口区“数商兴农工程”建设试点示范村项目",
          time: "2022年07月",
          src: require("@/assets/image/case/9.jpg"),
        },
        {
          name: "数字乡村类",
          title: "永川区南大街街道数字乡村建设",
          time: "2020年11月",
          src: require("@/assets/image/case/5.jpg"),
        },
        {
          name: "数字乡村类",
          title: "开州区白鹤街道数字乡村建设",
          time: "2020年09月",
          src: require("@/assets/image/case/4.jpg"),
        },
      ],
      // 产品展示
      productList: [
        {
          id: 0,
          img: require("../assets/image/banner/dis2.png"),
          title: "阿吉云天地宝",
          // price: "暂无",
          price: "￥200,000元/套",
          txt: "运用蚂蚁区块链技术，打造乡村农品阿吉云·蚂蚁链区块链溯源，将生长过程的海拔、光照、降雨、图片、土壤等实时数据赋能乡村产业，支撑乡村产业升级和原产地高端品牌溢价。",
        },
        {
          id: 1,
          img: require("../assets/image/banner/dis1.png"),
          title: "阿吉云生态宝",
          //price: "暂无",
          price: "￥100,000元/套",
          txt: "将乡村的海拔、负氧离子、温度、 pm2.5、分贝等数据，运用蚂蚁区块链技术，实时呈现乡村数字生态场景，让城市消费人群享受不一样的好生态。",
        },
        {
          id: 2,
          img: require("../assets/image/banner/dis3.png"),
          title: "阿吉云人车宝",
          // price: "暂无",
          price: "￥20,000元/套",
          txt: "通过物联网设备实时监测人流、车流，运用雷达感应监测系统，完成对人流、车流数据的统计。将人流、车流数据上传至云服务器，完成数据保存及管控，并发布到云数据平台，为游客及政府管理提供精准的数据服务。",
        },
        {
          id: 3,
          img: require("../assets/image/banner/dis4.png"),
          title: "阿吉云关爱宝",
          // price: "暂无",
          price: "￥600元/套",
          txt: "运用互联网、物联网、AI等技术，开发AI专属设备，建立人机互交、人机互动语音智能网，为老年人紧急事项提供高效的即时信息服务，形成老年人与村级组织、村级医务室、子女或者监护人的人文关怀机制，使和美乡村建设更温情，惠民有感。",
        },
      ],
      quanSwiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        spaceBetween: 38, //控制间隔
        slidesPerView: 6, //控制展示个数
        autoHeight: false,
        centeredSlides: false,
        grabCursor: false,
      }, // 知识产权轮播数据
      nav: [
        { name: "首页", url: "home" },
        { name: "公司简介", url: "info" },
        // { name: "行业介绍", url: "industry" },
        { name: "业务矩阵", url: "business" },
        { name: "案例介绍", url: "case" },
        { name: "产品展示", url: "product" },
        { name: "知识产权", url: "knowledge" },
        { name: "合作生态", url: "cooperation" },
      ],
      navIndex: 0,
      swiperOption: {
        loop: false,
        // autoplay: {
        //     delay: 3000,
        //     stopOnLastSlide: false,
        //     disableOnInteraction: false
        // },
        spaceBetween: 5, //控制间隔
        slidesPerView: 1, //控制展示个数
        autoHeight: false,
        centeredSlides: false,
        grabCursor: false,
      },
      bannerIndex: 0,
      // caseSwiperOption: {
      //     slidesPerView: 3,
      //     slidesPerGroup: 3,
      //     centeredSlides: true,
      //     spaceBetween: -35,
      //     loop: true,
      //     loopFillGroupWithBlank: true,
      //     pagination: {
      //         el: ".swiper-pagination",
      //         clickable: true,
      //         renderBullet: function (index, className) {
      //             return '<span class="' + className + '">' + (index + 1) + '</span>';
      //         },
      //     },
      //     navigation: {
      //         nextEl: ".swiper-button-next",
      //         prevEl: ".swiper-button-prev",
      //     },
      // },
      // caseList: [
      //     {name: "重庆市江北区五宝镇干坝村"},
      //     {name: "重庆市梁平区金带街道双桂村"},
      //     {name: "重庆市城口县龙田乡联丰村"},
      //     {name: "重庆市开州区白鹤街道四胜村"},
      //     {name: "重庆市大渡口区跳蹬镇金鳌村"},
      //     {name: "重庆市酉阳酉水河镇河湾村"},
      //     {name: "重庆市酉阳楠木乡红庄村"},
      //     {name: "重庆市奉节县安坪镇三沱村"},
      //     {name: "重庆市奉节县鹤峰乡柳池村"},
      // ],
      caseSwiperIndex: 0,
      ecology: [{}],
      list1: [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 19, 20, 21, 22, 23, 24],
      list2: [7, 8, 9, 10, 11, 12, 7, 8, 9, 10, 11, 12, 25, 26, 27, 28, 29],
      list3: [13, 14, 15, 16, 17, 18, 13, 14, 15, 16, 17, 18, 30, 31],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    caseBanSwiper() {
      return this.$refs.caseBanSwiper.swiper;
    },
    caseSwiper() {
      return this.$refs.caseSwiper.swiper;
    },
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let item = document.getElementsByTagName("section");
      this.scrollTop = scrollTop;
      for (let i = 0; i < item.length; i++) {
        if (scrollTop >= item[i].offsetTop - 10) {
          this.navIndex = i;
        }
      }
    },
    prev() {
      if (this.bannerIndex > 0) {
        this.bannerIndex = this.bannerIndex - 1;
        this.swiper.slideTo(this.bannerIndex);
      }
    },
    next() {
      if (this.bannerIndex <= 2) {
        this.bannerIndex = this.bannerIndex + 1;
        this.swiper.slideTo(this.bannerIndex);
      }
    },

    // 大图轮播
    banSlideChange() {
      this.banBannerIndex = this.$refs.myBanSwiper.swiper.activeIndex;
    },
    // 简介轮播
    jianSlideChange() {
      this.jianBannerIndex = this.$refs.jianBanSwiper.swiper.activeIndex;
    },
    // 案例轮播
    caseSlideChange() {
      this.caseBannerIndex = this.$refs.caseBanSwiper.swiper.realIndex;
    },
    casePrev() {
      this.caseBanSwiper.slidePrev();
      // if(this.caseBannerIndex > 0) {
      // 	this.caseBannerIndex = this.caseBannerIndex - 1;
      // 	this.caseBanSwiper.slideTo(this.caseBannerIndex);
      // }
    },
    caseNext() {
      this.caseBanSwiper.slideNext();
      // this.caseBanSwiper.slideTo(this.caseBannerIndex);
    },
    slideChange() {
      this.bannerIndex = this.$refs.mySwiper.swiper.activeIndex;
    },
    caseSwiperChange() {
      this.caseSwiperIndex = this.$refs.caseSwiper.swiper.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
// 公司简介样式
.jian-box {
  background: #f7f8fa;
  .c-box {
    height: 560px;
    overflow: hidden;
  }
}

// 业务矩阵样式
.business {
  //height: 641px;
  background: #ffffff; //#f7f8fa;
  padding-bottom: 100px;

  .business-box {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 636px;
      min-height: 600px;
      border-radius: 32px;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(155, 255, 224, 0.5);
      position: relative;
      z-index: 2;
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 140px;

      .tit-img {
        width: 220px;
        height: 225px;
        background: url("/src/assets/image/bicon.png") no-repeat;
        background-size: 100% 100%;
        text-indent: 50px;
        line-height: 210px;
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: -150px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }

      .zhan {
        height: 80px;
        background-color: #ffffff;
        opacity: 0.8;
        border-radius: 32px;
      }

      .title {
        font-size: 32px;
        font-weight: bold;
        color: #06c68a;
        text-align: center;
        padding-bottom: 30px;
      }

      .bar {
        width: 100%;
        height: 1px;
        background-color: #06c68a;
        margin: 10px 0;
      }

      .info-title {
        padding: 5px 0;
        line-height: 38px;
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        white-space: nowrap;
        text-align: justify;
        letter-spacing: 1px;
      }

      .h1 {
        padding: 5px 0;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        display: flex;

        .b {
          font-weight: bold;
          color: #06c68a;
          margin-right: 10px;
        }

        .c {
        }
      }
    }
  }
}

// 生态合作样式
.ecology {
  // background: #ffffff;
  background: #f7f8fa;
  padding-bottom: 40px;
}

// 案例介绍样式
.case-box {
  background: #f7f8fa;

  .case {
    width: 1300px;
    margin: 20px auto 0;
    display: flex;
    padding: 60px 0 100px 0;

    .lis {
      flex: none;
      width: 720px;
      height: 100%;
      height: 410px;
      position: relative;

      .lis-img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
      }

      .lis-info {
        position: absolute;
        background: rgba(0, 0, 0, 0.2);
        z-index: 2;
        width: 100%;
        bottom: 0px;
        left: 0px;
        color: #ffffff;
        padding: 10px 30px;
        box-sizing: border-box;

        .bar {
          display: inline-block;
          padding: 0 16px;
          height: 30px;
          line-height: 30px;
          background: #06c68a;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }

        .title {
          font-size: 22px;
          font-weight: bold;
        }

        .text {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .case-banner {
      width: 560px;
      height: 410px;
      margin-left: 20px;
      position: relative;

      .case-item {
        width: 560px;
        height: 124px;
        display: flex;

        .case-img {
          flex: none;
          width: 237px;
          height: 124px;
          position: relative;

          .img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
          }

          .title {
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-indent: 10px;
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            background: rgba(0, 0, 0, 0.3);

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            word-break: break-all;
          }
        }

        .inf {
          flex: auto;
          margin-left: 20px;
          color: #000000;

          .tit {
            font-size: 24px;
            font-weight: bold;
            position: relative;
            text-indent: 20px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            word-break: break-all;

            &::after {
              display: block;
              content: "";
              width: 8px;
              height: 31px;
              background: #06c68a;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .data {
            text-indent: 20px;
            margin-top: 40px;
            font-size: 16px;
            font-weight: 400;
          }

          .time {
            text-indent: 20px;
            font-size: 22px;
            font-weight: 400;
          }
        }
      }

      .swiper-button {
      }

      .swiper-button-prev {
        top: -30px;
        left: 50%;
        width: 44px;
        // transform: rotate(90deg);
        background-image: url(@/assets/image/top.png);
        background-size: 44px 44px;
      }

      .swiper-button-next {
        top: 110%;
        right: initial;
        left: 50%;
        width: 44px;
        background-image: url(@/assets/image/bottom.png);
        background-size: 44px 44px;
        // transform: rotate(90deg);
      }
    }
  }
}

// 联系我们样式
.footer {
  width: 100%;
  height: 559px;
  //background: #f7f8fa;
  margin-top: 20px;
  overflow: hidden;

  .container {
    padding-top: 90px;
    height: auto;

    .info-box {
      color: #000000;
      display: flex;
      padding-bottom: 20px;

      .tel-info {
        .title {
          font-size: 30px;
          font-weight: bold;
        }

        .tel-box {
          margin-top: 28px;
          display: flex;

          .tel-item {
            margin-right: 40px;

            .name {
              flex: none;
              font-size: 28px;
              text-indent: 24px;
              position: relative;

              &::after {
                display: block;
                content: "";
                width: 10px;
                height: 40px;
                background: #06c68a;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }

      .tel-code {
        flex: none;
        display: flex;
        margin-left: auto;

        .code-box {
          text-align: center;
          margin-left: 66px;

          .img {
            display: block;
            width: 195px;
            height: 195px;
          }

          .name {
            font-size: 28px;
            margin-top: 10px;
          }
        }
      }
    }

    .b {
      height: 149px;
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 16px;
        padding: 10px 0;
        color: #000;
      }

      a {
        color: #000;

        &:hover {
          color: #06c68a;
        }
      }
    }
  }
}

.home {
  min-width: 1600px;
  height: 100%;
  position: relative;

  .container {
    width: 1300px;
    height: 100%;
    margin: 0 auto;
  }

  .header {
    width: 100%;
    height: 95px;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }

  .header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header .container h1 img {
    width: 89px;
    height: 66px;
  }

  .nav {
    display: flex;
  }

  .nav li {
    list-style: none;
    padding: 0 30px;
    position: relative;
  }

  .nav li a {
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    opacity: 0.7;
  }

  .nav .this a {
    opacity: 1;
  }

  .nav .this::after {
    content: "";
    width: 50px;
    height: 4px;
    position: absolute;
    left: 50%;
    bottom: -35px;
    margin-left: -25px;
    background: #06c68a;
  }

  .bg {
    width: 100%;
  }

  .bg img {
    width: 100%;
    height: 842px; //700px;
  }

  .bigTitle {
    height: 180px;
    text-align: center;
  }

  .bigTitle .en {
    color: #333333;
    font-size: 18px;
    padding: 52px 0 10px 0;
    text-transform: uppercase;
  }

  .bigTitle .zh {
    color: #000000;
    font-size: 30px;
    font-weight: bold;
    position: relative;
  }

  .bigTitle .zh::after {
    content: "";
    width: 69px;
    height: 5px;
    background: #06c68a;
    position: absolute;
    left: 50%;
    bottom: -20px;
    margin-left: -34.5px;
  }

  .info {
    height: 650px;
    background: #f7f8fa;
  }

  .info .container {
    display: flex;
    align-items: center;
    text-align: justify;
  }

  .info .container .tp {
    width: 513px;
    height: 374px;
    position: relative;
    z-index: 1;
  }

  .info .container .tp::after {
    content: "";
    width: 192px;
    height: 374px;
    position: absolute;
    left: -15px;
    bottom: -15px;
    background: #06c68a;
    z-index: -1;
  }

  .info .container .tp::before {
    content: "";
    width: 192px;
    height: 374px;
    position: absolute;
    right: 19px;
    bottom: -15px;
    background: #06c68a;
    z-index: -1;
  }

  .info .container .tp img {
    width: 100%;
    height: 100%;
  }

  .info .content {
    flex: 1;
    margin-left: 57px;
  }

  .info .content .name {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    margin-bottom: 15px;
    // padding: 39px 0;
  }

  .info .content .name a {
    color: #000000;
  }

  .info .content .name a:hover {
    color: #06c68a;
  }

  .info .content p {
    width: 719px;
    // height: 163px;
    color: #333;
    font-size: 18px;
    line-height: 36px;
  }

  .el-button--success {
    width: 136px;
    height: 40px;
    background: #06c68a;
    font-size: 16px;
    border-color: #06c68a;
    margin-top: 48px;
  }

  .el-button--success:hover {
    background: #5ae4b9;
    border-color: #5ae4b9;
  }

  .el-button--success:active {
    background: #06c68a;
    border-color: #06c68a;
  }

  .banner {
    height: 460px;
    position: relative;
  }

  .banner .item {
    height: 420px;
    display: flex;
    align-items: center;
  }

  .banner .item .l {
    flex: 1;
    height: 100%;
    margin-right: 94px;
    display: flex;
    flex-direction: column;
  }

  .banner .item .l .name {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0 49px 0;
  }

  .banner .item .l .name a {
    color: #000;
  }

  .banner .item .l .name a:hover {
    color: #06c68a;
  }

  .banner .item .l p {
    flex: 1;
    color: #333333;
    font-size: 18px;
    line-height: 40px;
  }

  .banner .item .l p span {
    color: #06c68a;
    font-size: 22px;
  }

  .banner .item .l .btn {
    height: 130px;
  }

  .banner .item .r {
    width: 513px;
    height: 374px;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.06);
    margin-right: 20px;
  }

  .banner .item .r img {
    width: 100%;
    height: 100%;
  }

  .banner .swiper-btn {
    display: flex;
    float: right;
    margin-right: 34px;
  }

  .banner .swiper-btn div {
    width: 36px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #999999;
    margin-left: 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 1px;
  }

  .banner .swiper-btn div i {
    font-size: 20px;
    color: #999999;
  }

  .banner .swiper-btn .this {
    border-color: #06c68a;
  }

  .banner .swiper-btn .this i {
    color: #06c68a;
  }

  .business .list {
    width: 1300px;
    margin: 20px auto;
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .product {
    height: 809px;
    //background: #f7f8fa;
  }

  .product .list {
    // width: 1300px;
    // width: 654px;
    width: 1300px;
    // margin-top: 45px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product .list .mask {
    width: 237px;
    height: 42px;
    color: #ffff;
    text-align: center;
    line-height: 40px;
    background: #000000;
    border-radius: 0px 0px 0px 0px;
    opacity: 0.5;
    position: absolute;
    left: 8%;
    top: 50%;
  }

  .product .list img {
    width: 283px;
    height: 258px;
  }

  .product .list .title {
    display: flex;
    height: 22px;
    margin: 30px 0px 20px 0px;
    justify-content: space-between;
    border-left: 5px solid #06c68a;
  }

  .product .list .title .title-l {
    margin-left: 10px;
  }

  .product .list .txt {
    text-indent: 2em;
    width: 265px;
    height: 136px;
    font-size: 16px;
    margin-left: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #808080;
    line-height: 30px;
    text-align: justify;
  }

  .knowledge {
    //background: #f7f8fa;
    background: #ffffff;
    padding-bottom: 74px;

    .knowledge-title {
      font-size: 30px;
      font-weight: 400;
      color: #000000;
      position: relative;
      text-indent: 20px;
      margin-bottom: 40px;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 47px;
        background: #06c68a;
      }
    }
  }

  .knowledge .list {
    width: 1300px;
    margin: 20px auto;

    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .img {
      display: block;
      width: 180px;
      height: 285px;
    }
  }

  .business .list .item {
    width: 304px;
    // height: 393px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
    text-align: center;
    padding: 0 0 20px 0;
  }

  .business .list .item img {
    width: 66px;
    height: 66px;
    margin-top: 77px;
  }

  .business .list .item .name {
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-top: 50px;
    position: relative;
    padding-bottom: 25px;
  }

  .business .list .item .name::after {
    content: "";
    width: 20px;
    height: 2px;
    background: #06c68a;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: 0;
  }

  .business .list .item:hover .name {
    color: #06c68a;
  }

  .business .list .item p {
    padding: 0 18px;
    color: #333333;
    line-height: 30px;
    font-size: 16px;
    margin-top: 25px;
    text-align: left;
  }

  // .case {
  //   width: 1250px;
  //   height: 500px;
  //   margin: 0 auto;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   position: relative;
  // }
  // .case .swiper-container {
  //   width: 1100px;
  //   padding: 0 5px 60px 5px;
  //   position: static;
  // }
  // .case .item {
  //   height: 436px;
  //   position: relative;
  // }
  // .case .item img {
  //   width: 100%;
  //   height: 100%;
  // }
  // .case .item .name {
  //   width: 100%;
  //   height: 70px;
  //   background: rgba(0, 0, 0, 0.5);
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   font-size: 18px;
  //   color: #fff;
  //   line-height: 70px;
  //   text-align: center;
  // }
  // .case .swiper-slide {
  //   transform: scale(0.78);
  //   transition: 300ms;
  // }
  // .case .swiper-slide-active {
  //   transform: scale(0.92);
  //   transition: 300ms;
  // }
  // .case .swiper-slide-active .name {
  //   background-color: rgba(6, 198, 138, 0.5);
  //   font-size: 20px;
  //   font-weight: bold;
  // }

  // .case .swiper-button {
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 50%;
  //   background-color: rgba(0, 0, 0, 0.1);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   cursor: pointer;
  //   background-image: none;
  // }
  // .case .swiper-button i {
  //   font-size: 20px;
  //   color: #fff;
  // }
  // .case .swiper-button-prev {
  //   left: 1px;
  // }
  // .case .swiper-button-next {
  //   right: 1px;
  // }
  // .case .swiper-pagination-bullet {
  //   background-color: transparent;
  //   width: 42px;
  //   height: 42px;
  //   border: 2px solid #666666;
  //   text-align: center;
  //   line-height: 42px;
  //   border-radius: 1px;
  //   margin: 0 7px;
  //   opacity: 1;
  //   position: relative;
  // }
  // .case .swiper-pagination-bullet-active {
  //   color: #06c68a;
  //   font-size: 16px;
  //   font-weight: bold;
  //   border-color: #06c68a;
  // }
  // .case .swiper-pagination-bullet:nth-of-type(3)::after {
  //   content: "…";
  //   width: 42px;
  //   height: 42px;
  //   position: absolute;
  //   top: 50%;
  //   margin-top: -28px;
  //   left: 50px;
  //   color: #666666;
  //   font-size: 24px;
  //   font-weight: normal;
  // }
  // .case .btn {
  //   width: 138px;
  //   position: absolute;
  //   left: 777px;
  //   bottom: 15px;
  //   z-index: 99;
  // }
  // .case .btn .el-button--success {
  //   width: 100%;
  //   height: 42px;
  //   border: 2px solid #06c68a;
  //   background-color: transparent;
  //   color: #06c68a;
  //   font-size: 16px;
  //   cursor: pointer;
  // }

  .ecology .scroll {
    width: 100%;
    position: relative;
  }

  .ecology .scroll .bg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 1)
    );
  }
}
</style>
