import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home';
Vue.use(VueRouter);

const routes = [
    {
		path: '/',
		component: Home,
        name: 'Home'
	}
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes
});
  
// 处理路由跳转后的，顶部菜单栏操作
router.beforeEach((to, from, next) => {
    next();
});
  
export default router;